"use client";
import { useAppSelector } from "@hooks";
import { useRouter } from "next/navigation";
import React, { useState } from "react";
import { Box } from "@mui/material";
import { MainTemplate } from "@components/templates";

import HeroSection from "@next/components/organisms/landing-page/hero-section/hero-section";
import HowItWorksSection from "@next/components/organisms/landing-page/how-it-works-section/how-it-works-section";
import ContributeAndImproveSection from "@next/components/organisms/landing-page/contribute-and-improve-section/contribute-and-improve-section";
import GetStartedSection from "@next/components/organisms/landing-page/get-started-section/get-started-section";
import { RatingModal } from "@components/organisms";

export const Main = () => {
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const { loggedIn } = useAppSelector((state) => state.auth);
  const router = useRouter();
  const handleRatingModalOpen = () => {
    setRatingModalOpen(true);
  };
  loggedIn && router.push("/home");
  
  return (
    <MainTemplate>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <HeroSection handleRatingModalOpen={handleRatingModalOpen} />
        <HowItWorksSection handleRatingModalOpen={handleRatingModalOpen} />
        <ContributeAndImproveSection />
        <GetStartedSection />
      </Box>
      <RatingModal
        open={ratingModalOpen}
        onclose={() => setRatingModalOpen(false)}
      />
    </MainTemplate>
  );
};
